import { graphql } from "gatsby";
import * as React from "react";
import Seo from "../components/seo";
import { A2, A5 } from "../components/fonts";
import PropTypes from "prop-types";
import Container from "../components/container";

export default function BlogPostTemplate({ data: { markdownRemark } }) {
  const { frontmatter, html } = markdownRemark;
  return (
    <>
      <Seo title={frontmatter.title} />
      <Container>
        <div className="col-span-30 md:col-span-15 relative flex flex-col bg-wbStone px-6">
          <A2 className="sticky top-0 pt-32 pb-32 pr-20 text-wbTruss leading-title-tight">
            {frontmatter.title}{" "}
            <span className="text-wbSand">{frontmatter.subtitle}</span>
          </A2>
          <A5 className="sticky bottom-0 mt-auto py-6 uppercase text-wbTruss">
            BY <span className="text-wbTruss">{frontmatter.author}</span> —{" "}
            {frontmatter.date}
          </A5>
        </div>
        <div className="col-span-30 md:col-span-15 md:mt-32">
          <div
            className="max-w-prose pt-12 md:pt-0 pb-24 px-6 post-body"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </Container>
    </>
  );
}

BlogPostTemplate.propTypes = {
  data: PropTypes.Object,
  html: PropTypes.Object,
  title: PropTypes.String,
  subtitle: PropTypes.String,
  date: PropTypes.String,
  author: PropTypes.String,
};

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        author
        subtitle
        title
      }
    }
  }
`;
